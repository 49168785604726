import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Learning App Template | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/learning-mobile-app/"
    metaDescription="Designing your own mobile learning app? Creating your app just got a whole lot easier with LearnU, our mobile e-learning app design template."
    description="
    h2:Try LearnU, our learning app design template for mobile devices
    <br/>
    Designing your own mobile learning or education app for your brand or service? Creating your app just got a whole lot easier with LearnU, our mobile e-learning app design template. LearnU comes packed with all the must have elements and UX considerations built in, meaning you can easily tweak to suit your MVP before handing off to a developer for production!
    <br/>
    h3:No need to go back to design school, non-designers are welcome 
    <br/>
    Uizard is a prototyping and <a:https://uizard.io/wireframing/>UX/UI wireframing tool</a> for non-designers, made for easy-of-use and rapid product design - essentially, creating your own design in Uizard is super-fast and super-easy. With our design templates though, you can really fast-track your design flow. Sign up now and craft your own learning app design in minutes.
    <br/>
    h3:Get an easy A with all your future design projects 
    <br/>
    Want to put your own stamp of branding or vision on our premade <a:https://uizard.io/templates/>UI design template</a>? No problem. Uizard is all about customization, meaning you can add new elements, change screens, update user flows, and even adapt the entire color palette of LearnU. Make our e-learning app template your own today!
    "
    pages={[
      "Course overview screens with classic, education app design choices",
      "Personal statistics overview with course progress and performance analytics",
      "Course previews with text elements for expanded detail",
      "Search directory of all your prospective courses",
    ]}
    projectCode="O4GOXyY8bzSnJQ6vEzXr"
    img1={data.image1.childImageSharp}
    img1alt="mobile learning app design overview"
    img2={data.image2.childImageSharp}
    img2alt="mobile learning app design course screen"
    img3={data.image3.childImageSharp}
    img3alt="mobile learning app design course search screen"
    img4={data.image4.childImageSharp}
    img4alt="mobile learning app design home screen"
    img5={data.image5.childImageSharp}
    img5alt="mobile learning app design summary"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/learning-mobile-app/learning-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/learning-mobile-app/learning-app-course.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/learning-mobile-app/learning-app-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/learning-mobile-app/learning-app-home.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/learning-mobile-app/learning-app-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
